<template>
  <div>
    <Modal v-model="modifyModal" :mask-closable="false" width="600">
      <p slot="header" class="text-center">{{ opType === 1 ? '新增' : '编辑' }}主题媒体产品</p>
      <Form ref="formValidate" :model="productModel" :rules="ruleValidate" :label-width="140">
        <FormItem label="所属资产" prop="assetIds">
          <Select v-model="productModel.assetIds" size="small" placeholder="选择资产">
            <Option v-for="item in assetArray" :key="'assetId_' + item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="所属产品" prop="productId">
          <Select v-model="productModel.productId" size="small" placeholder="选择产品">
            <Option v-for="item in productArray" :key="'product_' + item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="名称" prop="productskuName">
          <Input v-model="productModel.productskuName" size="small" placeholder="填写名称" />
        </FormItem>
        <FormItem label="资源">
          <Button type="primary" size="small" @click="handleChoiceRes">选择资源</Button>
          <div v-show="selectedResArray.length">
            <Tag v-for="item in selectedResArray" :key="item.id">{{ item.code }}</Tag>
          </div>
        </FormItem>
        <FormItem label="最小售卖天数" prop="minday">
          <InputNumber v-model="productModel.minday" :min="1" size="small" style="width:100%" placeholder="最小售卖天数">
          </InputNumber>
        </FormItem>
        <FormItem label="媒介使用费" prop="usePrice">
          <InputNumber :min="0" v-model="productModel.usePrice"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入媒介使用费">
          </InputNumber>
        </FormItem>
        <!-- <FormItem label="代理商结算价" prop="statementPrice">
          <InputNumber :min="0" v-model="productModel.statementPrice"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入代理商结算价">
          </InputNumber>
        </FormItem> -->
        <!-- <FormItem label="服务费刊例价">
          <InputNumber :min="0" v-model="productModel.produceCost"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入服务费刊例价">
          </InputNumber>
        </FormItem>
        <FormItem label="服务费结算价">
          <InputNumber :min="0" v-model="productModel.produceStatement"
            :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\￥\s?|(,*)/g, '')" size="small" style="width:100%" placeholder="输入服务费结算价">
          </InputNumber>
        </FormItem> -->
        <FormItem label="是否可用">
          <i-switch size="large" v-model="productModel.enabled">
            <span slot="open">是</span>
            <span slot="close">否</span>
          </i-switch>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="modifyModal = false">取消</Button>
        <Button type="primary" @click="handleSave">确定</Button>
      </div>
    </Modal>
    <Modal v-model="resModal" :mask-closable="false" footer-hide width="1000">
      <p slot="header" class="text-center">选择资源</p>
      <Tabs v-model="resBoxShow" size="small">
        <TabPane label="选择资源" name="tab_choice">
          <div>
            <Row :gutter="8" class="m-b-5">
              <i-col span="4">
                <Select v-model="resQuery.assetId" :cleable="false" size="small" placeholder="选择资产"
                  @on-change="handleChangeAsset">
                  <Option v-for="item in assetArray" :key="'aid_' + item.id" :value="item.id">{{ item.name }}</Option>
                </Select>
              </i-col>
              <i-col span="4">
                <i-select size="small" placeholder="站点" clearable v-model="resQuery.stationId">
                  <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ item.name }}</i-option>
                </i-select>
              </i-col>
              <i-col span="4">
                <i-select size="small" placeholder="位置" clearable v-model="resQuery.position">
                  <i-option v-for="item in positionArray" :value="item.value" :key="'pos_' + item.value">{{ item.name
                  }}</i-option>
                </i-select>
              </i-col>
              <i-col span="4">
                <i-select size="small" placeholder="资源类型(可搜索)" clearable filterable v-model="resQuery.resourcetypeId">
                  <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
                    <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
                    </Option>
                  </OptionGroup>
                </i-select>
              </i-col>
              <i-col span="2">
                <i-select size="small" placeholder="状态" clearable v-model="resQuery.enabled">
                  <i-option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{ item.name
                  }}</i-option>
                </i-select>
              </i-col>

              <i-col span="4">
                <i-input size="small" placeholder="关键字：支持名称查询" v-model="resQuery.keyword"></i-input>
              </i-col>
              <i-col span="2">
                <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch">搜索</i-button>
              </i-col>
            </Row>
            <div class="m-b-5">
              <Button type="success" size="small" :disabled="selectedResArray.length === 0"
                @click="handleConfirmRes">确认加入</Button>
            </div>
            <Table size="small" stripe :data="resList" :columns="resColumns" @on-selection-change="handleSelectedRes">
            </Table>
            <div class="paging_style">
              <Page size="small" :total="resTotal" :page-size="resQuery.pageSize" show-total show-elevator
                :current="resQuery.pageNumber" @on-change="handlePageChange"></Page>
            </div>
          </div>
        </TabPane>
        <TabPane label="已有资源" name="tab_exist">
          <template v-if="selectedResArray.length">
            <Row class="m-b-10">
              <i-col span="20">提示：常规媒体可以不用编辑，电子媒体根据实际情况进行编辑。</i-col>
              <i-col span="4" class="text-right">
                <Button type="success" size="small" @click="handleSaveResInfo">保存信息</Button>
              </i-col>
            </Row>
            <Row :gutter="8" class="table-title">
              <i-col span="6" class="p-l-5">资源编号</i-col>
              <i-col span="6" class="p-l-5">售卖规格（秒或天）</i-col>
              <i-col span="6" class="p-l-5">售卖周期数（次）</i-col>
              <i-col span="6"></i-col>
            </Row>
            <Row :gutter="8" class="m-b-5" v-for="(item, index) in selectedResArray" :key="'res_item_' + item.id">
              <i-col span="6">
                <Tag color="default">{{ item.code }}</Tag>
              </i-col>
              <i-col span="6">
                <InputNumber v-model="item.duration" :min="1" size="small" style="width:100%" placeholder="最小售卖规格">
                </InputNumber>
              </i-col>
              <i-col span="6">
                <InputNumber v-model="item.period" :min="1" size="small" style="width:100%" placeholder="每天周期数">
                </InputNumber>
              </i-col>
              <i-col span="6">
                <Button type="error" size="small" @click="handleDeleteResItem(item, index)">删除</Button>
              </i-col>
            </Row>
          </template>
        </TabPane>
      </Tabs>

    </Modal>
  </div>
</template>

<script>
import { productMixins } from '../mixins/product'
import { commonMixins } from '@/assets/mixins/common'

import { getPositions, getResourcePage } from '@/api/product/resource'
import { getResourceTypeList } from '@/api/product/asset'
import { getStationByAsset } from '@/api/product/station'
import { addFix, editFix } from '@/api/product/productskuedit'

export default {
  mixins: [productMixins, commonMixins],
  props: {
    saveSuccessFun: Function
  },
  data () {
    return {
      modifyModal: false,
      productModel: {
        assetIds: null,
        categoryId: 4, // 主题媒体，类目id固定为4
        desc: '',
        enabled: true,
        minday: 1,
        productId: null,
        productskuName: '',
        usePrice: 1,
        statementPrice: 0, // 代理商结算价
        produceCost: 0, // 服务费
        produceStatement: 0, // 服务费结算价
        resourceInfos: '',
        cityCode: '',
        cityName: ''
      },
      productskuId: null, // 当前主题产品id，编辑更新时用
      existResInfos: [], // 当前主题媒体已有资源信息
      ruleValidate: {
        assetIds: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        categoryId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        minday: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        productId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        productskuName: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        usePrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ],
        statementPrice: [
          { required: true, type: 'number', message: ' ', trigger: 'blur' }
        ]
      },
      resModal: false,
      resBoxShow: 'tab_choice',
      resQuery: {
        pageSize: 15,
        pageNumber: 1,
        assetId: null,
        stationId: null,
        enabled: null,
        position: null,
        resourcetypeId: null,
        keyword: ''
      },
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' }
      ],
      resList: [],
      resTotal: 0,
      resColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '编号', align: 'center', key: 'code' },
        { title: '所属站点', align: 'center', key: 'stationName' },
        { title: '资源类型', align: 'center', key: 'resourcetypeName' },
        {
          title: '状态',
          align: 'center',
          key: 'enabled',
          render: (h, params) => {
            return h('span', params.row.enabled ? '可用' : '不可用')
          }
        }
      ],
      selectedResArray: []
    }
  },
  computed: {
    assetArray () {
      return this.$store.state.productManage.assetArray
    },
    storageProducts () {
      return this.$store.state.productManage.productArray
    },
    opType () {
      return this.$store.state.productManage.opType
    }
  },
  mounted () {
    this.getCompanyInfo()
  },
  methods: {
    initShow (data) {
      if (this.opType === 1) {
        // 新增
        this.resBoxShow = 'tab_choice'
        this.selectedResArray = []
        Object.assign(this.$data.productModel, this.$options.data().productModel)
      } else {
        // 编辑
        if (data) {
          // // 先获取产品数据
          // this.getProductData({ categoryId: data.categoryId }).then(res => {
          //   // 防止赋值不成功
          //   this.productModel.productId = data.productId
          // })
          this.productModel.productId = data.productId
          this.productModel.assetIds = parseInt(data.assetIds)
          this.productModel.categoryId = data.categoryId
          this.productModel.desc = data.desc
          this.productModel.enabled = data.enabled
          this.productModel.minday = data.minday
          this.productModel.productskuName = data.name
          // this.productModel.fixResourceList = data.fixResourceList
          this.productModel.usePrice = data.usePrice
          this.productModel.produceCost = data.produceCost
          this.productModel.cityCode = data.cityCode
          this.productModel.cityName = data.cityName
          this.productModel.statementPrice = data.statementPrice
          this.productModel.produceStatement = data.produceStatement
          this.productskuId = data.id

          this.resBoxShow = 'tab_exist'
          this.selectedResArray = data.fixResourceList.map(x => {
            return {
              id: x.resourceId,
              code: x.resourceCode,
              duration: x.duration,
              period: x.period,
              deviceId: x.deviceId
            }
          })
        }
      }
      this.modifyModal = true
    },
    handleChoiceRes () {
      this.resQuery.assetId = this.productModel.assetIds || this.assetArray[0].id
      this.initStationArray()
      this.getPositionData()
      this.getResourceTypeData()
      this.getResData()
      this.resModal = true
    },
    // 初始化站点选项
    initStationArray () {
      this.resQuery.stationId = null
      getStationByAsset({ assetId: this.resQuery.assetId }).then((res) => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      getPositions({ assetIds: JSON.stringify([this.resQuery.assetId]) }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      getResourceTypeList({ assetId: this.resQuery.assetId }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    getResData () {
      getResourcePage(this.resQuery).then(res => {
        if (res && !res.errcode) {
          this.resList = res.list
          this.resTotal = res.totalRow
          this.resQuery.pageNumber = res.pageNumber
          this.resList.forEach(item => {
            item._checked = false
            if (this.selectedResArray.some(x => x.id === item.id)) {
              item._checked = true
            }
          })
        } else {
          this.resList = []
          this.resTotal = 0
          this.resQuery.pageNumber = 1
        }
      })
    },
    handleChangeAsset () {
      this.initStationArray()
      this.getPositionData()
      this.handleSearch()
    },
    handlePageChange (curPage) {
      // this.selectedResArray = []
      this.prevSelectionArray = []
      this.resQuery.pageNumber = curPage
      this.getResData()
    },
    handleSearch () {
      // this.selectedResArray = []
      this.resQuery.pageNumber = 1
      this.getResData()
    },
    handleSelectedRes (selection) {
      const curSelections = selection.map(x => {
        return {
          id: x.id,
          code: x.code,
          duration: 1,
          period: 1
        }
      })

      // 获取当前删除的项
      const delItems = this.resList.filter(x => !curSelections.some(s => s.id === x.id))
      if (delItems.length) {
        // 排除已删除项数据
        this.selectedResArray = this.selectedResArray.filter(x => !delItems.some(s => s.id === x.id))
      }

      curSelections.forEach(item => {
        if (!this.selectedResArray.some(x => x.id === item.id)) {
          this.selectedResArray.push(item)
        }
      })
    },
    handleConfirmRes () {
      this.resBoxShow = 'tab_exist'
    },
    handleDeleteResItem (item, index) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该项？',
        onOk: () => {
          this.selectedResArray.splice(index, 1)
          this.getResData()
        }
      })
    },
    handleSaveResInfo () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要保存当前信息？',
        onOk: () => {
          this.resModal = false
          // this.resBoxShow = 'tab_choice'
        }
      })
    },
    handleSave () {
      if (this.selectedResArray.length === 0) {
        this.$Notice.error({ desc: '请选择资源' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: () => {
          const resInfos = this.selectedResArray.map(x => {
            return {
              resourceId: x.id,
              duration: x.duration,
              period: x.period
            }
          })
          this.productModel.resourceInfos = JSON.stringify(resInfos)
          this.productModel.assetIds = JSON.stringify([this.productModel.assetIds])

          if (this.opType === 1) {
            this.productModel.cityCode = this.companyInfo.cityCode
            this.productModel.cityName = this.companyInfo.cityName
            addFix(this.productModel).then(res => {
              this.modifyModal = false
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.selectedResArray = []
                this.saveSuccessFun()
              }
            })
          } else {
            const postData = Object.assign(this.productModel, { productskuId: this.productskuId })
            editFix(postData).then(res => {
              this.modifyModal = false
              if (res && res.errcode === 0) {
                this.$Notice.success({ desc: '操作成功' })
                this.selectedResArray = []
                this.saveSuccessFun()
              }
            })
          }
        }
      })
    }
  },
  watch: {
    storageProducts: {
      deep: true,
      immediate: true,
      handler (val) {
        this.productArray = val
      }
    }
  }
}
</script>
